const fonts = {
  primary:
    'font-family: "Archivo", sans-serif; font-optical-sizing: auto; font-style: normal;letter-spacing: -2%;',
  secondary:
    'font-family: "Funnel Sans", sans-serif; font-optical-sizing: auto; font-style: normal; letter-spacing: -2%;',
  captionRegular:
    'font-family: "Chivo Mono", monospace; font-weight: 400; font-style: normal; text-transform: uppercase;',
  captionItalic:
    'font-family: "Chivo Mono", monospace; font-weight: 400; font-style: italic; text-transform: uppercase;',
};
export default fonts;
